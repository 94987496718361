










































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';

import NextSectionButton from '@/components/Buttons/NextSectionButton.vue';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';

import BrandsByOfficeBarChart from '@/components/Statistics/BrandsByOfficeBarChart.vue';
import BrandsByNiceClassBarChart from '@/components/Statistics/BrandsByNiceClassBarChart.vue';
import BrandsByStateCategoryDonutChart from '@/components/Statistics/BrandsByStateCategoryDonutChart.vue';
import BrandsByBrandTypeDonutChart from '@/components/Statistics/BrandsByBrandTypeDonutChart.vue';
import BrandThumbnailsCarousel from '@/components/Statistics/BrandThumbnailsCarousel.vue';
import TotalBrandsBox from '@/components/Statistics/TotalBrandsBox.vue';
import OneColumn from '@/components/Layout/OneColumn.vue';
import TwoColumns from '@/components/Layout/TwoColumns.vue';
import { BusyObject } from '@/models/Busy';

@Component({
  components: {
    NextSectionButton,
    BrandsByOfficeBarChart,
    BrandsByNiceClassBarChart,
    BrandsByStateCategoryDonutChart,
    BrandsByBrandTypeDonutChart,
    BrandThumbnailsCarousel,
    TotalBrandsBox,
    OneColumn,
    TwoColumns
  },
  metaInfo() {
    return {
      title: this.$i18n.t("insights.title").toString(),
      meta: [
        // Search Engines:
        { name: 'title', content: this.$i18n.t("insights.metaTags.title").toString() },
        { name: 'description', content: this.$i18n.t("insights.metaTags.description").toString() },
        { name: 'keywords', content: this.$i18n.t("metaTags.keywords").toString() },
        { name: 'author', content: this.$i18n.t("metaTags.author").toString() },
        { name: 'language', content: this.$i18n.locale },
        { name: 'robots', content: [RobotOption.All].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("insights.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("insights.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Insights extends Vue {

  // Member:
  public statisticUsOverall = new BusyObject<any>();
  public statisticUsLastMonth = new BusyObject<any>();

  public statisticDeOverall = new BusyObject<any>();
  public statisticDeLastMonth = new BusyObject<any>();

  public statisticAtOverall = new BusyObject<any>();
  public statisticAtLastMonth = new BusyObject<any>();

  public statisticChOverall = new BusyObject<any>();
  public statisticChLastMonth = new BusyObject<any>();

  public statisticEsOverall = new BusyObject<any>();
  public statisticEsLastMonth = new BusyObject<any>();

  public statisticFrOverall = new BusyObject<any>();
  public statisticFrLastMonth = new BusyObject<any>();

  public statisticEmOverall = new BusyObject<any>();
  public statisticEmLastMonth = new BusyObject<any>();

  // Lifecycle Methods:
  public mounted() {
    this.$store.dispatch("updateMasterData");

    this.loadStatisticsUsOverall();
    this.loadStatisticsUsLastMonth();

    this.loadStatisticsDeOverall();
    this.loadStatisticsDeLastMonth();
    
    this.loadStatisticsAtOverall();
    this.loadStatisticsAtLastMonth();

    this.loadStatisticsChOverall();
    this.loadStatisticsChLastMonth();

    this.loadStatisticsEsOverall();
    this.loadStatisticsEsLastMonth();

    this.loadStatisticsFrOverall();
    this.loadStatisticsFrLastMonth();

    this.loadStatisticsEmOverall();
    this.loadStatisticsEmLastMonth();
  }

  public async loadStatisticsDeOverall() {
      await this.statisticDeOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/de/all.json');
      });
  }

  public async loadStatisticsDeLastMonth() {
      await this.statisticDeLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/de/last90d.json');
      });
  }

  public async loadStatisticsAtOverall() {
      await this.statisticAtOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/at/all.json');
      });
  }

  public async loadStatisticsAtLastMonth() {
      await this.statisticAtLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/at/last90d.json');
      });
  }

  public async loadStatisticsChOverall() {
      await this.statisticChOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/ch/all.json');
      });
  }

  public async loadStatisticsChLastMonth() {
      await this.statisticChLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/ch/last90d.json');
      });
  }

  public async loadStatisticsEsOverall() {
      await this.statisticEsOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/es/all.json');
      });
  }

  public async loadStatisticsEsLastMonth() {
      await this.statisticEsLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/es/last90d.json');
      });
  }

  public async loadStatisticsFrOverall() {
      await this.statisticFrOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/fr/all.json');
      });
  }

  public async loadStatisticsFrLastMonth() {
      await this.statisticFrLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/fr/last90d.json');
      });
  }

  public async loadStatisticsEmOverall() {
      await this.statisticEmOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/em/all.json');
      });
  }

  public async loadStatisticsEmLastMonth() {
      await this.statisticEmLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/em/last90d.json');
      });
  }

  public async loadStatisticsUsOverall() {
      await this.statisticUsOverall.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/us/all.json');
      });
  }

  public async loadStatisticsUsLastMonth() {
      await this.statisticUsLastMonth.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/us/last90d.json');
      });
  }

}
